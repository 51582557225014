import { Form, Input } from 'antd';
import React from 'react';

const TextInput = ({ label, field, required }) => {
  return (
    <div key={field}>
      <Form.Item hasFeedback label={label} name={field} rules={[{ required, message: `${label} is required` }]}>
        <Input />
      </Form.Item>
    </div>
  );
};

export default TextInput;
