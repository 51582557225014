import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class WatchdogMachineService {
  getTabData(params) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    };

    const option = {
      url: `${API_URL}/machine_cycle/get_data`,
    };

    return axios.get(option.url, config);
    // const token = loginservice.getToken();

    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    //   params,
    // };

    // const option = {
    //   url: 'https://demo-live-data.highcharts.com/aapl-v.json',
    // };

    // return axios.get(option.url);
  }
}
