export const setUserCompanyData = data => {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_USER_BY_COMPANY',
        payload: data,
      });
    } catch (err) {
      console.log('catch');
    }
  };
};

export const setUserRoomsData = data => {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_USER_ROOMS',
        payload: data,
      });
    } catch (err) {
      console.log('catch');
    }
  };
};
