import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import PublicRoutes from '../routes/publicRoutes';
import Auth from '../routes/auth';
import ProtectedRoute from '../components/utilities/protectedRoute';
import Admin from '../routes/admin';

const RouterHelper = () => {
  const { isLoggedIn } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });
  const location = useLocation();
  const isRootPath = useMemo(() => {
    if (location?.pathname) {
      const { pathname } = location;
      return pathname === process.env.PUBLIC_URL || pathname === `${process.env.PUBLIC_URL}/`;
    }
    return false;
  }, [location]);
  return (
    <>
      {!isLoggedIn ? (
        !isRootPath ? (
          <Route path="/" component={PublicRoutes} />
        ) : (
          <Route path="/" component={Auth} />
        )
      ) : (
        <ProtectedRoute path="/admin" component={Admin} />
      )}
      {isLoggedIn && isRootPath && <Redirect to="/admin" />}
    </>
  );
};

export default RouterHelper;
