import { Checkbox, Form } from 'antd';
import React from 'react';

const CheckboxInput = ({ label, field }) => {
  return (
    <Form.Item valuePropName="checked" hasFeedback label={label} name={field}>
      <Checkbox />
    </Form.Item>
  );
};

export default CheckboxInput;
