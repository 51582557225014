export const defaultExtremes = {
  min: null,
  max: null,
};

const dcaReducer = (state = defaultExtremes, action) => {
  switch (action.type) {
    case 'EXTREMES':
      return action?.payload;
    default:
      return state;
  }
};
export default dcaReducer;
