import React, { useState } from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { colors } from '../../../layout/colors';

const { Title } = Typography;

const DeleteConfirmationModal = ({ handleConfirm, handleCancel, warningMessage, name, visible, secure }) => {
  const [confirmationName, setConfirmationName] = useState('');
  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <WarningTwoTone style={{ fontSize: '2rem' }} twoToneColor={colors.warning} />
          <div>
            <Title style={{ margin: '0 0 0 1rem' }} level={4}>{`Delete ${name || ''}`}</Title>
          </div>
        </div>
      }
      onCancel={handleCancel}
      visible={visible}
      footer={[
        <Button onClick={handleCancel}>No</Button>,
        <Button disabled={secure ? confirmationName !== name : false} onClick={handleConfirm} type="primary" danger>
          Yes
        </Button>,
      ]}
    >
      <span>{warningMessage}</span>
      {secure && (
        <>
          <span>Type the name again to confirm deletion:</span>
          <Input
            style={{ marginTop: '1rem' }}
            placeholder="Name to confirm delete"
            onChange={e => setConfirmationName(e.target.value)}
          />
        </>
      )}
    </Modal>
  );
};

export default DeleteConfirmationModal;
