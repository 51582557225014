import { useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { logOut } from '../../../redux/authentication/actionCreator';
import { selectCompanyHandler } from '../../../redux/dashboard/actions';
import { SWITCH_DEMO_MODE } from '../../../redux/demoMode';
import { showPrivacyModal } from '../../../redux/privacyPolicy/actions';
import { setUserCompanyData, setUserRoomsData } from '../../../redux/userCompany/action';
import LoginService from '../../../service/login';
import UserService from '../../../service/user';
import { setItem } from '../../../utility/localStorageControl';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { InfoWraper, UserDropDwon } from './auth-info-style';

const loginservice = new LoginService();
const userService = new UserService();

async function getUserInfo() {
  const { payload: user } = loginservice.getDecodedToken();
  if (user.role_id === 1) {
    return { user, company: null };
  }
  const response = await userService.getUserCompany(user.id);
  const company = response.data.data;
  const companySites = user.role_id === 2 ? [] : response.data.data.company_sites;
  const roomsCount = response.data.data.count;
  return {
    user,
    company,
    companySites,
    roomsCount,
  };
}

const AuthInfo = () => {
  const { path } = useRouteMatch();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const demoMode = useSelector(state => state.demoMode);
  const { data } = useQuery({ queryKey: ['user-info'], queryFn: getUserInfo });

  const user = useMemo(() => data?.user || null, [data]);
  const isSuperAdmin = useMemo(() => user?.role_id === 1, [user]);

  useEffect(() => {
    if (data?.company) {
      dispatch(
        selectCompanyHandler({
          id: data.company.id,
          name: data.company.name,
          slug: data.company.slug,
        }),
      );
      if (data.companySites && data.roomsCount) {
        dispatch(setUserCompanyData(data.companySites));
        dispatch(setUserRoomsData(data.roomsCount));
      }
    } else {
      dispatch(setUserCompanyData(''));
    }
  }, [data, dispatch]);

  const SignOut = e => {
    e.preventDefault();
    dispatch(selectCompanyHandler({ id: '', name: '', slug: '' }));
    dispatch(setUserCompanyData());
    dispatch(setUserRoomsData());
    setItem('user');
    dispatch(logOut());
    loginservice.Logout();
  };

  const onSwitchDemoMode = () => {
    dispatch({
      type: SWITCH_DEMO_MODE,
    });
  };

  const userContent = (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <UserDropDwon>
        <div className="user-dropdwon">
          <figure className="user-dropdwon__info">
            <figcaption>
              <Heading as="h5">{user?.email}</Heading>
            </figcaption>
          </figure>
          {isSuperAdmin ? (
            <li>
              <Button type={demoMode.enabled ? 'default' : 'dashed'} onClick={onSwitchDemoMode}>
                {demoMode.enabled ? 'Disable' : 'Enable'} demo mode
              </Button>
            </li>
          ) : null}
          <ul className="user-dropdwon__links">
            <li>
              <Link to={`${path}/profile`}>
                <FeatherIcon icon="user" /> Profile
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  setVisible(false);
                  dispatch(showPrivacyModal(true));
                }}
              >
                <FeatherIcon icon="eye" /> Privacy policy
              </Link>
            </li>
          </ul>
          <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="/">
            <FeatherIcon icon="log-out" /> Sign Out
          </Link>
        </div>
      </UserDropDwon>
    </OutsideClickHandler>
  );

  const handleVisibleChange = () => {
    setVisible(false);
  };

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover
          placement="bottomRight"
          content={userContent}
          action="click"
          visible={visible}
          handleVisibleChange={handleVisibleChange}
        >
          <Link
            to="#"
            className="head-example"
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <FeatherIcon icon="user" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
