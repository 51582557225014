/* eslint-disable import/order */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router';
import PdfService from '../../../../service/pdf';
import Header from '../../header';
import '../../../watchdog/eventLog/notification/style.scss';
// import 'antd/dist/antd.css';
import '../../../../static/css/style.css';

const pdfService = new PdfService();

const NotificationPdf = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [companyName, setCompanyName] = useState();
  useEffect(() => {
    pdfService.getPdf(id).then(response => {
      setData(response?.data?.data);
      setCompanyName(response?.data?.company_name);
    });
  }, []);
  return (
    <>
      <Row
        justify="center"
        style={{
          padding: '30px 20px',
        }}
      >
        <Col
          span={24}
          style={{
            padding: '0px 25px',
          }}
        >
          <Header companyName={companyName} />
        </Col>
        <Col span={24} className="main-backend-content-noification">
          <h2
            className="heading"
            style={{
              padding: '25px 0px 0px 25px',
            }}
          >
            Notifications
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.html,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default NotificationPdf;
