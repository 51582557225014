const initialState = {
  user: '',
  rooms: '',
};

const userByCompany = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_BY_COMPANY':
      return {
        ...state,
        user: action.payload,
      }
    case 'SET_USER_ROOMS':
      return {
        ...state,
        rooms: action.payload,
      };

    default:
      return state;
  }
};
export default userByCompany;
