import Styled from 'styled-components';

const RoomsModalStyle = Styled.div`
.ant-select-multiple .ant-select-selection-item-content{
    margin-right:10px!important;
}
.ant-select-multiple .ant-select-selection-item {
    padding:8px!important;
    color:#fff!important;
    height: unset!important;
    background: #20C997!important;
    border:none!important;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
    color:#fff!important;
}
.rooms-modal-form{
    min-height:60vh;
}
`;

export { RoomsModalStyle };
