const initialState = {
  filters: {},
  firmness: [],
  color: [],
};

const storageMeasurementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTERS_DASHBOARD':
      return {
        ...state,
        filters: action.payload,
      };
    case 'FIRMNESS':
      return {
        ...state,
        firmness: action.payload,
      };
    case 'COLOR':
      return {
        ...state,
        color: action.payload,
      };

    default:
      return state;
  }
};
export default storageMeasurementReducer;
