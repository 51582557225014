import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class Pdf {
  createPdf(data, id, value, license) {
    const payload = {
      html: data?.toString(),
      company_id: id,
      pdf: value,
      license,
    };
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/dashboard/create_pdf`,
    };
    return axios.post(option.url, payload, config);
  }

  createPdfLogs(data, rooms, value, license) {
    const payload = {
      html: data?.toString(),
      room_logs_id: rooms,
      pdf: value,
      license,
    };
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/dashboard/create_pdf_logs`,
    };
    return axios.post(option.url, payload, config);
  }

  getPdf(id) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/dashboard/get_pdf?id=${id}`,
    };
    return axios.get(option.url, config);
  }
}
