import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import DashboardHeader from '../../components/dashboard-header';
import WatchdogMachineDashboard from '../watchdog-machine-dashboard';
import WatchDogMachine from '../watchdog-machine-details';
import { Cards } from '../../components/cards/frame/cards-frame';

const WatchdogMachineContainer = ({ location }) => {
  const globalStates = useSelector(state => state?.Dashboard);
  const states = useSelector(state => state?.userByCompany);
  const filterState = useSelector(state => state?.filter);
  const [siteIds, setSiteIds] = useState('');
  const [filters, setFilters] = useState({
    license: 'machineCycle',
    company: globalStates?.companyId || '',
  });

  useEffect(() => {
    setFilters(filter => ({
      ...filter,
      company: globalStates?.companyId,
    }));
  }, [globalStates.companyId]);

  useEffect(() => {
    let sitesls = '';
    if (states?.user?.length > 0) {
      sitesls = states.user.map(x => x.id);
    }
    setSiteIds(sitesls);
    setFilters(filter => ({
      ...filter,
      site: sitesls,
    }));
  }, [states]);

  useEffect(() => {
    setFilters({
      ...filterState,
      license: 'machineCycle',
      company: globalStates?.companyId || '',
    });
  }, [filterState]);

  return (
    <>
      <PageHeader ghost title="Performance Module" />
      <Main isMachine>
        <Cards headless>
          <DashboardHeader siteIds={siteIds} isMachineCycle />
        </Cards>
        {window.location.href.includes('details') ? (
          <WatchDogMachine filters={filters} location={location} />
        ) : (
          <WatchdogMachineDashboard filters={filters} />
        )}
      </Main>
    </>
  );
};

export default WatchdogMachineContainer;
