import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class CultivarsService {
  getCultivars() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/cultivars/get_cultivar`,
    };

    return axios.post(option.url, {}, config);
  }
}
