import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { teamReducer } from './team/reducers';
import { userReducer, userGroupReducer } from './users/reducers';
import { sellersReducer } from './sellers/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import Dashboard from './dashboard/reducers';
import PrivacyModal from './privacyPolicy/reducers';
import StorageInsight from './storageInsight/reducers';
import Room from './rooms/reducers';
import UserCompany from './userCompany/reducers';
import orderReducer from './orders/reducers';
import galleryReducer from './gallary/reducers';
import chartContentReducer from './chartContent/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import { productReducer, SingleProductReducer } from './product/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import cartData from './cart/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Task from './task/reducers';
import kanbanBoardReducer from './kanban/reducers';
import Contact from './contact/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import FileManager from './fileManager/reducers';
import filterReducer from './filter/reducers';
import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';
import { demoModeReducer } from './demoMode';
import dcaReducer from './dca/reducers';
import chartReducer from './chart/reducers';
import selectOptionsReducer from './selectOptions/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  AxiosCrud: axiosCrudReducer,
  Calender,
  cart: cartData,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  chat: chatReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  Contact,
  Dashboard,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  FileManager,
  gallery: galleryReducer,
  groupChat: groupChatReducer,
  headerSearchData: headerSearchReducer,
  KanbanBoard: kanbanBoardReducer,
  message: readMessageReducer,
  Note,
  notification: readNotificationReducer,
  orders: orderReducer,
  PrivacyModal,
  product: SingleProductReducer,
  products: productReducer,
  Profile,
  project: SingleProjectReducer,
  projects: projectReducer,
  Room,
  sellers: sellersReducer,
  SingleAxiosCrud: axiosSingleCrudReducer,
  StorageInsight,
  Task,
  team: teamReducer,
  themeUsers: themeUsersReducer,
  Todo,
  userByCompany: UserCompany,
  userGroup: userGroupReducer,
  users: userReducer,
  filter: filterReducer,
  demoMode: demoModeReducer,
  dca: dcaReducer,
  chart: chartReducer,
  selectOptions: selectOptionsReducer,
});

export default rootReducers;
