import { getItem, setItem } from '../../utility/localStorageControl';

const previouslySelectedCompany = getItem('selectedCompany');
const initialState = previouslySelectedCompany || {
  companyId: '',
  companyName: '',
  companySlug: '',
};

const dashboardReducer = (state = initialState, action) => {
  const selectedCompany = {
    ...state,
    companyId: action?.payload?.id || '',
    companyName: action?.payload?.name || '',
    companySlug: action?.payload?.slug || '',
  };
  switch (action.type) {
    case 'COMPANY_ID':
      setItem('selectedCompany', selectedCompany);
      return selectedCompany;
    default:
      return state;
  }
};
export default dashboardReducer;
