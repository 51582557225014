import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class CompaniesService {
  getCompanies() {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/companies/get_company`,
    };
    return axios.get(option.url, config);
  }
}
