import styled from 'styled-components';

export const UploadPageContainer = styled.div`
  margin: 4rem 4rem 0rem 2rem;
  min-height: 80vh;
  & .ant-steps-item-title::after {
    background: #e0e1e2 !important;
  }
  & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #00ca95 !important;
  }
`;

export const StepContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: center;
  background-color: #f0f1f2;
  border: 1px dashed #e0e1e2;
  border-radius: 5px;
`;

export const StepActionContainer = styled.div`
  margin: 24px 0;
`;

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  & button {
    width: 180px;
    height: 70px;
  }
  & button > * {
    font-size: 1.2rem;
  }
`;

export const GraphContainer = styled.div`
  margin: 2rem 0;
  width: 100%;
  flex: 1;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectionSummaryContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectionInfo = styled.div`
  border: 3px solid grey;
  padding: 3px;
  margin: 1rem;
`;
