import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useMemo } from 'react';
import { Empty } from 'antd';
import {
  exportOnlyVisibleData,
  handleDataGrouping,
  getTimezoneOffset,
  loadZoomPeriod,
} from '../../../../utility/chart';
import { formatTime } from '../../../watchdog-machine-dashboard/utils';

exportOnlyVisibleData(Highcharts);
Highcharts.setOptions({
  global: {
    getTimezoneOffset,
  },
});

const MachineCycleChart = ({ data, measurementType, zoomPeriod, setZoomPeriod }) => {
  const isTimes = useMemo(() => measurementType.includes('_times'), [measurementType]);
  const series = useMemo(
    () =>
      data.map(seriesObject => ({
        ...seriesObject,
        data: seriesObject.data.map(measurement => [measurement?.timestamp, parseFloat(measurement[measurementType])]),
      })),
    [data, measurementType],
  );
  const options = useMemo(
    () => ({
      title: null,
      credits: {
        enabled: false,
      },
      chart: {
        height: 320,
        reflow: false,
        alignTicks: false,
        marginLeft: 85,
        marginRight: 20,
        zoomType: 'xy',
        events: {
          load() {
            const chart = this;
            loadZoomPeriod(chart, zoomPeriod);
          },
        },
      },
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 3,
      },
      lang: {
        noData: 'No data to display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
      rangeSelector: {
        selected: 1,
      },
      maintainAspectRatio: true,
      responsive: true,
      series,
      exporting: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        maxPadding: 0.01,
        events: {
          setExtremes(e) {
            const { chart, series: chartSeries } = this;
            if (!e.min && !e.max) {
              setZoomPeriod([null, null]);
              const { resetZoomButton } = chart;
              if (resetZoomButton) {
                resetZoomButton.hide();
              }
            } else {
              setZoomPeriod([e.min, e.max]);
            }
            handleDataGrouping(e.min, e.max, chartSeries, false);
          },
        },
      },
      yAxis: {
        title: {
          text: isTimes ? 'HH:mm:ss' : 'Actions',
        },
        type: isTimes ? 'datetime' : null,
        dateTimeLabelFormats: isTimes
          ? {
              second: '%H:%M:%S',
              minute: '%H:%M:%S',
              hour: '%H:%M:%S',
              day: '%H:%M:%S',
              week: '%H:%M:%S',
              month: '%H:%M:%S',
              year: '%H:%M:%S',
            }
          : null,
      },
      tooltip: {
        headerFormat: '',
        pointFormatter() {
          const point = this;
          const tooltipValue = isTimes
            ? `Duration: <b>${formatTime(parseInt(point.y / 1000, 10))}</b><br/>`
            : `Actions: <b>${point.y}</b> <br/>`;
          const tooltipDate = `Date: ${Highcharts.dateFormat('%Y-%m-%d', point.x)}`;
          return `${tooltipValue}${tooltipDate}`;
        },
      },
    }),
    [isTimes, series, setZoomPeriod, zoomPeriod],
  );

  return (
    <>
      {data?.length ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <Empty imageStyle={{ height: 200 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};
export default MachineCycleChart;
