import React from 'react';
import moment from 'moment';
import { TimeField } from './style';

export const machineTimeTabs = [
  { key: 'cooling_actions', name: 'Cooling Actions' },
  { key: 'cooling_times', name: 'Cooling Times', unit: 'HH:mm:ss' },
  { key: 'defrosting_actions', name: 'Defrosting Actions' },
  { key: 'defrosting_times', name: 'Defrosting Times', unit: 'HH:mm:ss' },
  { key: 'aeration_actions', name: 'Aerations Actions' },
  { key: 'aeration_times', name: 'Aerations Times', unit: 'HH:mm:ss' },
  { key: 'scrubbing_actions', name: 'Scrubbing Actions' },
  { key: 'scrubbing_times', name: 'Scrubbing Times', unit: 'HH:mm:ss' },
];

export function formatMachineValue(value) {
  return parseInt(value, 10);
}

function lengthFormat(number) {
  const formatValue = `${number}`;
  if (formatValue.length === 1) {
    return `0${formatValue}`;
  }
  return formatValue;
}

export function formatTime(seconds) {
  const hoursRemainder = seconds % 3600;
  const hours = (seconds - hoursRemainder) / 3600;
  const minutesRemainder = hoursRemainder % 60;
  const minutes = (hoursRemainder - minutesRemainder) / 60;
  return `${lengthFormat(hours)}:${lengthFormat(minutes)}:${lengthFormat(minutesRemainder)}`;
}

export function getColumns(history, userFormat) {
  return [
    {
      title: 'Room',
      key: 'room',
      dataIndex: 'name',
    },
    {
      title: 'Cultivar',
      key: 'cultivar',
      render: record => record?.room_contents[0]?.cultivar?.title,
    },
    ...machineTimeTabs.map((tab, tabIndex) => {
      return {
        title: tab.unit ? `${tab.name} [${tab.unit}]` : tab.name,
        key: tab.name,
        sorter: (a, b) => {
          const aTimes = a?.machine_times[0];
          const bTimes = b?.machine_times[0];
          return aTimes[tab?.key] - bTimes[tab?.key];
        },
        render: record => {
          const times = record?.machine_times[0];
          let valueToShow = times ? formatMachineValue(times[tab?.key]) : '';
          if (!Number.isNaN(valueToShow) && tab.key.includes('times')) {
            valueToShow = formatTime(valueToShow);
          }
          return (
            <TimeField
              onClick={e => {
                e.stopPropagation();
                const newPath = `/admin/watchdog/machine-cycle/details?selectedRooms=${record?.id}&tab=${tabIndex}`;
                history.push(newPath, '1');
              }}
            >
              {valueToShow}
            </TimeField>
          );
        },
      };
    }),
    {
      title: 'Last update',
      key: 'lastUpdate',
      render: record => {
        const { updatedAt } = record.machine_times[0];
        const format = userFormat || 'DD/MM/YY HH:mm';
        return updatedAt ? moment(updatedAt).format(format) : '--/--/--';
      },
    },
    {
      title: 'Leakage',
      key: 'leakage',
      render: record => {
        const { leakage } = record;
        return leakage || '----';
      },
    },
  ];
}
