import { Col, Popover, Row, Spin, Tabs, Typography, DatePicker } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { HeaderWrapper } from '../../components/dashboard-header/style';
import { H6 } from '../../components/heading/style';
import RoomsModal from '../../components/rooms-modal/index';
import useDashboard from '../../hooks/useDashboard';
import useMachineCycle from '../../hooks/useMachineCycle';
import { content } from '../../utility/helpers';
import { getSelectedRoomsFromQueryParams } from '../../utility/utility';
import MachineCycleChart from './overview/column-stack';
import { machineTimeTabs } from '../watchdog-machine-dashboard/utils';

const { TabPane } = Tabs;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const selectableDays = [
  {
    label: '1d',
    value: 1,
  },
  {
    label: '7d',
    value: 7,
  },
  {
    label: '30d',
    value: 30,
  },
  {
    label: '90d',
    value: 90,
  },
  {
    label: 'All',
    value: 0,
  },
];

function makeSeriesForSelectRooms(chartData, valueKey, rooms) {
  if (!chartData?.length || !rooms?.length) {
    return [];
  }
  const series = chartData.reduce((acc, curr) => {
    const { datetime, room_id: roomId } = curr;
    const date = new Date(datetime);
    if (!acc[roomId]) {
      acc[roomId] = [{ ...curr, timestamp: date.getTime() }];
    } else {
      acc[roomId].push({ ...curr, timestamp: date.getTime() });
    }
    return acc;
  }, {});

  const dataToDisplay = Object.entries(series)
    .map(([key, value]) => {
      const roomInfo = rooms.find(r => r.id === Number(key));
      const valuesForChart = value.map(v => {
        const newValues = { ...v };
        Object.keys(v).forEach(k => {
          if (k.includes('_times')) {
            newValues[k] = (parseFloat(v[k]) * 1000).toString();
          }
        });
        return newValues;
      });
      if (roomInfo) {
        return {
          type: 'line',
          name: roomInfo.name,
          label: roomInfo.name,
          data: valuesForChart,
        };
      }
      return null;
    })
    .filter(o => o !== null);
  return dataToDisplay;
}

function parseTab(text) {
  const split = text?.split('tab=');
  return split?.length === 2 ? parseInt(split[1], 10) : null;
}

function getTabIndex(key) {
  const tab = machineTimeTabs.find(t => t.key === key);
  return machineTimeTabs.indexOf(tab);
}

const WatchDogMachine = ({ location, filters }) => {
  const ref = useRef();
  const history = useHistory();
  const [selectedRooms, setSelectedRooms] = useState(
    location.search ? getSelectedRoomsFromQueryParams(location.search) : [],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryTab = useMemo(() => parseTab(location?.search), [location.search]);
  const [timeTab, setTimeTab] = useState(queryTab ? machineTimeTabs[queryTab]?.key : machineTimeTabs[0].key);
  const { rooms } = useDashboard(filters);
  const [days, setDays] = useState(0);
  const [isRangeSelected, setIsRangeSelected] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const { data: chartData, isFecthing: isChartDataLoading } = useMachineCycle(
    selectedRooms?.length ? selectedRooms : null,
    { cacheTime: 0 },
    days,
    dateRange,
  );
  const [zoomPeriod, setZoomPeriod] = useState(null);
  const selectedRoomIndex = useMemo(() => {
    if (!selectedRooms.length) {
      return -1;
    }
    return rooms.findIndex(r => r.id === selectedRooms[0]);
  }, [rooms, selectedRooms]);

  const cycleChartData = useMemo(() => {
    return makeSeriesForSelectRooms(chartData?.machineTime, timeTab, rooms);
  }, [chartData, rooms, timeTab]);

  const handleRoomBack = useCallback(() => {
    if (selectedRoomIndex > 0) {
      const { id } = rooms[Math.max(0, selectedRoomIndex - 1)];
      setSelectedRooms([id]);
    }
  }, [rooms, selectedRoomIndex]);

  const handleRoomForward = useCallback(() => {
    if (selectedRoomIndex < rooms.length - 1) {
      const { id } = rooms[Math.max(0, selectedRoomIndex + 1)];
      setSelectedRooms([id]);
    }
  }, [rooms, selectedRoomIndex]);

  const handleRoomModal = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, []);

  const handleRoomModalcancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleRoomChange = useCallback(
    value => {
      const tabIndex = getTabIndex(timeTab);
      history.replace({
        pathname: history.location.pathname,
        search: `?selectedRooms=${value.join()}&tab=${tabIndex}`,
      });
      setSelectedRooms(value);
    },
    [history, timeTab],
  );

  useEffect(() => {
    const roomIds = rooms.map(room => room?.id);
    if (roomIds.length) {
      const roomIdsToRemove = [];
      selectedRooms.forEach(roomId => {
        if (!roomIds.includes(roomId)) {
          roomIdsToRemove.push(roomId);
        }
      });
      if (roomIdsToRemove.length) {
        const updatedSelectedRooms = selectedRooms.filter(id => !roomIdsToRemove.includes(id));
        setSelectedRooms(updatedSelectedRooms);
      }
    }
  }, [rooms]);

  const handleTabChange = useCallback(
    key => {
      const newTabIndex = getTabIndex(key);
      history.replace({
        pathname: location.pathname,
        search: `?selectedRooms=${selectedRooms.join(',')}&tab=${newTabIndex}`,
      });
      setTimeTab(key);
    },
    [history, location.pathname, selectedRooms],
  );

  const dayButtonStyling = buttonDays => ({
    borderColor: !isRangeSelected && days === buttonDays ? '#22d6a0' : '',
  });

  const handleRange = async value => {
    if (value === null) {
      setDateRange({ start: '', end: '' });
      return;
    }
    const start = new Date(value[0]);
    const end = new Date(value[1]);
    setIsRangeSelected(true);
    setZoomPeriod([null, null]);
    setDateRange({
      start,
      end,
    });
  };

  const handleDateChange = day => {
    setZoomPeriod([null, null]);
    setIsRangeSelected(false);
    setDateRange({ start: '', end: '' });
    setDays(day);
  };

  return (
    <>
      <RoomsModal
        visible={isModalOpen}
        handleRoomModalcancel={handleRoomModalcancel}
        handleRoomModal={handleRoomModal}
        dropdownOpen
        handleRoomChange={handleRoomChange}
        rooms={rooms}
        selected={selectedRooms}
      />
      <div id="create-pdf-machine" ref={ref}>
        <Cards headless>
          <HeaderWrapper>
            <Row justify="space-between" className=" ">
              <Col lg={10}>
                {selectedRooms.length ? (
                  selectedRooms.map((roomId, i) => {
                    const roomData = rooms.find(row => row.id === roomId);
                    return (
                      <Row gutter={12} className="align-items-center" key={`${roomData?.id}-${i}`}>
                        <Col>
                          <Title level={5}>{roomData?.name}</Title>
                        </Col>
                        <Popover placement="rightTop" content={content(roomData)}>
                          <FeatherIcon icon="info" size={16} />
                        </Popover>
                      </Row>
                    );
                  })
                ) : (
                  <H6>Select a room to see its data</H6>
                )}
              </Col>
              <Col lg={10} className="header-top-right d-flex justify-content-end">
                {selectedRooms.length === 1 && selectedRoomIndex > 0 && (
                  <Button type="default" onClick={handleRoomBack}>
                    <FeatherIcon icon="chevron-left" size={16} />
                  </Button>
                )}{' '}
                <Button
                  type="success"
                  onClick={() => {
                    handleRoomModal();
                  }}
                >
                  Room(s)
                </Button>{' '}
                {selectedRooms.length === 1 && selectedRoomIndex < rooms.length - 1 && (
                  <Button type="default" onClick={handleRoomForward}>
                    <FeatherIcon icon="chevron-right" size={16} />
                  </Button>
                )}
              </Col>
            </Row>
          </HeaderWrapper>
        </Cards>

        {selectedRooms?.length ? (
          <>
            <Cards headless>
              <Row className="filters-row" justify="space-between" gutter={16}>
                <Col>
                  {selectableDays?.map((dayOption, i) => (
                    <Button
                      type="dashed"
                      style={dayButtonStyling(dayOption?.value)}
                      onClick={() => handleDateChange(dayOption?.value)}
                      key={`${dayOption.label}-${i}`}
                    >
                      {dayOption?.label}
                    </Button>
                  ))}
                </Col>
                <Col>
                  <RangePicker
                    onChange={handleRange}
                    value={[
                      dateRange?.start ? moment(dateRange.start) : '',
                      dateRange?.end ? moment(dateRange.end) : '',
                    ]}
                  />
                </Col>
              </Row>
              <Tabs onChange={handleTabChange} activeKey={timeTab} items={machineTimeTabs} destroyInactiveTabPane>
                {isChartDataLoading ? (
                  <Col xs={24}>
                    <div className="spin">
                      <Spin />
                    </div>
                  </Col>
                ) : (
                  <>
                    {machineTimeTabs.map(tab => (
                      <TabPane tab={tab.name} key={tab.key}>
                        <MachineCycleChart
                          data={cycleChartData}
                          measurementType={tab.key}
                          zoomPeriod={zoomPeriod}
                          setZoomPeriod={period => setZoomPeriod(period)}
                        />
                      </TabPane>
                    ))}
                  </>
                )}
              </Tabs>
            </Cards>
          </>
        ) : null}
      </div>
    </>
  );
};
export default WatchDogMachine;
