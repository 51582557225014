import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppLayout from '../../layout/AppLayout';
import WatchdogMachineContainer from '../../container/watchdog-machine-container';
import WatchdogDashboardPdf from '../../pdf/watchdog/dashboard/watchdogPdf';
import MeasurmentPdf from '../../pdf/watchdog/measurment/measurmentPdf';
import NotificationPdf from '../../pdf/watchdog/eventLog/notification/notification';
import LogsPdf from '../../pdf/watchdog/eventLog/logs/logs';
import StorageInsightPdf from '../../pdf/storageInsight/dashboard/storageInisghtPdf';
import StorageInsightMesurment from '../../pdf/storageInsight/measurment/measurment';
import NotificationPdfStorageInsight from '../../pdf/storageInsight/eventLog/notification/notification';
import LogsPdfStorageInsight from '../../pdf/storageInsight/eventLog/logs/logs';
import DcaMeasurement from '../../pdf/dca/measurement/measurement';
import DcaDashboardPdf from '../../pdf/dca/dashboard/dashboard';
import OpticontrolDashboardPdf from '../../pdf/opticontrol/dashboard';
import UploadUlo from '../../container/upload-ulo';
import MainDashboard from '../../container/main-dashboard';

const MeasurementsModule = lazy(() => import('../../container/measurements'));
const CustomDashboard = lazy(() => import('../../container/watchdog-dashboard'));
const Measurements = lazy(() => import('../../container/watchdog-measurements'));
const EventLogs = lazy(() => import('../../container/watchdog-event-logs'));

const StorageInsightDashboard = lazy(() => import('../../container/storage-insight-dashboard'));
const StorageInsightMeasurements = lazy(() => import('../../container/storage-measurements'));
const StorageInsightEventLogs = lazy(() => import('../../container/storage-insight-event-logs'));
const StorageInsightIntakes = lazy(() => import('../../container/intakes'));
const IntakeDetails = lazy(() => import('../../container/intakes/details'));

const OpticontrolDashboard = lazy(() => import('../../container/opticontrol-dashboard'));
const SystemDashboard = lazy(() => import('../../container/opticontrol-system'));

const DcaDashboard = lazy(() => import('../../container/dca-dashboard'));
const DcaEventLog = lazy(() => import('../../container/dca-event-log'));
const DCAMeasurements = lazy(() => import('../../container/dca-measurements'));

const ProfileDetails = lazy(() => import('../../container/profileDetails/index'));

const TermModal = lazy(() => import('../../components/term-and-use'));
const CookiesModal = lazy(() => import('../../components/cookies'));
const PrivacyPolicy = lazy(() => import('../../components/privacy-policy'));

export const homePath = '/home/dashboard';

function Admin() {
  const { path } = useRouteMatch();

  return (
    <AppLayout>
      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route exact path={`${path}/pdf/watchdog/dashboard/:id`} component={WatchdogDashboardPdf} />
          <Route exact path={`${path}/pdf/watchdog/measurment/:id`} component={MeasurmentPdf} />
          <Route exact path={`${path}/pdf/watchdog/notification/:id`} component={NotificationPdf} />
          <Route exact path={`${path}/pdf/watchdog/log/:id`} component={LogsPdf} />
          <Route exact path={`${path}/pdf/storage-insight/dashboard/:id`} component={StorageInsightPdf} />
          <Route exact path={`${path}/pdf/storage-insight/measurement/:id`} component={StorageInsightMesurment} />
          <Route
            exact
            path={`${path}/pdf/storage-insight/notification/:id`}
            component={NotificationPdfStorageInsight}
          />
          <Route exact path={`${path}/pdf/storage-insight/log/:id`} component={LogsPdfStorageInsight} />
          <Route exact path={`${path}/pdf/dca/dashboard/:id`} component={DcaDashboardPdf} />
          <Route exact path={`${path}/pdf/dca/measurement/:id`} component={DcaMeasurement} />
          <Route exact path={`${path}/pdf/opticontrol/dashboard/:id`} component={OpticontrolDashboardPdf} />
          <Route exact path={path} component={MainDashboard} />
          <Route exact path={`${path}${homePath}`} component={MainDashboard} />
          <Route path={`${path}/profile`} component={ProfileDetails} />
          <Route path={`${path}/watchdog/dashboard`} component={CustomDashboard} />
          <Route path={`${path}/watchdog/measurements`} component={Measurements} />
          <Route path={`${path}/watchdog/machine-cycle`} component={WatchdogMachineContainer} />
          <Route path={`${path}/watchdog/event-log`} component={EventLogs} />

          <Route path={`${path}/storage-insights/dashboard`} component={StorageInsightDashboard} />
          <Route path={`${path}/storage-insights/measurements`} component={StorageInsightMeasurements} />
          <Route path={`${path}/storage-insights/event-log`} component={StorageInsightEventLogs} />

          <Route exact path={`${path}/quality-insights/intake/:id`} component={IntakeDetails} />
          <Route path={`${path}/quality-insights/dashboard`} component={StorageInsightIntakes} />

          <Route path={`${path}/opticontrol/dashboard`} component={OpticontrolDashboard} />
          <Route path={`${path}/opticontrol/uploadUlo`} component={UploadUlo} />
          <Route path={`${path}/opticontrol/system`} component={SystemDashboard} />

          <Route path={`${path}/dca/dashboard`} component={DcaDashboard} />
          <Route path={`${path}/dca/measurements`} component={DCAMeasurements} />
          <Route path={`${path}/dca/event-log`} component={DcaEventLog} />

          <Route path={`${path}/home/measurements`} component={MeasurementsModule} />

          <TermModal />
          <CookiesModal className="pdf-generate-hide" />
          <PrivacyPolicy />
        </Suspense>
      </Switch>
    </AppLayout>
  );
}

export default Admin;
