import React, { useMemo } from 'react';
import { Table, Col, Spin } from 'antd';
import { useHistory } from 'react-router';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper } from '../styled';
import useDashboard from '../../hooks/useDashboard';
import LoginService from '../../service/login';
import { getColumns } from './utils';
import { TableContainer } from './style';

const loginService = new LoginService();

const WatchdogMachineDashboard = ({ filters }) => {
  const history = useHistory();
  const user = useMemo(() => loginService.getDecodedToken(), []);
  const { rooms, isLoading } = useDashboard(filters);

  const tableSource = useMemo(() => {
    return rooms
      .map(room => ({ ...room, enabled: room?.machine_times?.length }))
      .sort((x, y) => (x?.enabled === y?.enabled ? 0 : x?.enabled ? -1 : 1));
  }, [rooms]);

  const columns = useMemo(() => getColumns(history, user.payload.format), [history, user.payload.format]);

  return (
    <>
      <Cards headless>
        <TableWrapper className="table-responsive mt-20">
          {isLoading ? (
            <Col xs={24}>
              <div className="spin">
                <Spin />
              </div>
            </Col>
          ) : (
            <>
              <TableContainer className="watch-dog-table">
                <Table
                  showSorterTooltip={false}
                  pagination={false}
                  className="components-table-demo-nested"
                  dataSource={tableSource}
                  scroll={{ x: 1650, y: 600 }}
                  onRow={record => {
                    const newPath = `/admin/watchdog/machine-cycle/details?selectedRooms=${record?.id}`;
                    return {
                      onClick: () => {
                        history.push(newPath, '1');
                      },
                    };
                  }}
                  childrenColumnName="children"
                  rowKey="id"
                  columns={columns}
                />
              </TableContainer>
            </>
          )}
        </TableWrapper>
      </Cards>
    </>
  );
};

export default WatchdogMachineDashboard;
