export const optifluxColors = {
  lightGreen: '#00CA95',
  darkGreenGradient: ['#003829', '#00CA95'],
  darkGreen: '#003829',
  greenYellowGradient: ['#00CA95', '#CAC200'],
  whiteBackground: '#FFFFFF',
};

export const colors = {
  warning: '#f0ad4e',
  error: '#cc3300',
};
