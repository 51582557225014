import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class SitesService {
  getSites(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/sites/get_all_site`,
    };

    return axios.post(option.url, data, config);
  }
  getSitesbyId(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/sites/get_site_by_id`,
    };

    return axios.post(option.url, data, config);
  }
}
