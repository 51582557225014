import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class GrowersService {
  getGrowers(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/growers/company_growers`,
    };

    return axios.post(option.url, data, config);
  }
}
