import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class TreatmentsService {
  getTreatments() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/treatments/get_treatment`,
    };

    return axios.get(option.url, config);
  }
}
