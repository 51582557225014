import { Col, Row } from 'antd';
import React from 'react';
import { LoginWrapper } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <LoginWrapper>
        <Row>
          <Col lg={12} md={0} xs={0} className="banner-section">
            <img
              style={{ width: '20vw' }}
              className="small-logo"
              src={require('../../../static/img/auth/logo-small.svg')}
              alt=""
            />
            <img
              className="auth-content-figure"
              style={{ height: '100vh' }}
              src={require('../../../static/img/auth/banner-new.png')}
              alt=""
            />
          </Col>

          <Col lg={{ span: 12 }} md={24} xs={24} className="login-form-col">
            <WraperContent />
          </Col>
        </Row>
      </LoginWrapper>
    );
  };
};

export default AuthLayout;
