import React, { useCallback, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import ReactDOMServer from 'react-dom/server';
import { optifluxColors } from '../../../layout/colors';

const UloGraph = ({ uloData, startDate, fields, showCurrentDate }) => {
  const initialTimestamp = useMemo(() => startDate?.unix() * 1000, [startDate]);
  const defaultSeriesProps = useMemo(
    () => ({
      type: 'line',
      pointStart: initialTimestamp,
    }),
    [initialTimestamp],
  );
  const series = useMemo(() => {
    return uloData
      .reduce((acc, value) => {
        const daysToAdd = parseInt(value?.day, 10) || 0;
        const timestampValue = initialTimestamp + daysToAdd * (24 * 3600 * 1000);
        Object.keys(value).forEach((key, i) => {
          if ((key === 'o2Set' || key === 'co2Set' || key === 'tempSet') && !Number.isNaN(parseFloat(value[key]))) {
            const setValue = parseFloat(value[key]);
            if (!acc[i]) {
              acc[i] = {
                ...defaultSeriesProps,
                name: key,
                data: [[timestampValue, setValue]],
                // color: Highcharts.getOptions().colors[i],
              };
            } else {
              acc[i].data.push([timestampValue, setValue]);
            }
          }
        });
        return acc;
      }, [])
      .filter(s => !!s);
  }, [defaultSeriesProps, initialTimestamp, uloData]);

  const getSeriesLabelString = useCallback(
    key => {
      return ReactDOMServer.renderToString(fields.find(field => field.key === key)?.label);
    },
    [fields],
  );

  const chartOptions = useMemo(() => {
    if (!series) return null;
    return {
      title: {
        text: 'Selected ULO Profile',
      },
      credits: {
        enabled: false,
      },
      chart: {
        height: 320,
        reflow: false,
        alignTicks: false,
        marginLeft: 85,
        marginRight: 20,
        zoomType: 'xy',
        backgroundColor: '#f4f5f7',
        borderRadius: '5px',
      },
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 3,
      },
      lang: {
        noData: 'No data to display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
      maintainAspectRatio: true,
      responsive: true,
      series,
      exporting: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        maxPadding: 0.01,
        plotLines: showCurrentDate
          ? [
              {
                label: {
                  text: 'Present',
                },
                color: 'black',
                width: 1,
                value: new Date().getTime(),
                dashStyle: 'dash',
                zIndex: 4,
              },
            ]
          : [],
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        crosshairs: {
          color: optifluxColors.lightGreen,
          dashStyle: 'LongDash',
        },
        useHTML: true,
        shared: true,
        formatter() {
          const { x, points } = this;
          const pointLabels = points.map(
            point =>
              `<span style="color: ${point.color}">${getSeriesLabelString(point.series?.name)}</span>: <b>${
                point.y
              }</b><br />`,
          );
          return `${Highcharts.dateFormat('%Y-%m-%d', x)}<br />${pointLabels.join('')}`;
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      legend: {
        useHTML: true,
        labelFormatter() {
          const { name } = this;
          return getSeriesLabelString(name);
        },
      },
    };
  }, [getSeriesLabelString, series]);
  return <HighchartsReact key={startDate?.format()} highcharts={Highcharts} options={chartOptions} />;
};

export default UloGraph;
