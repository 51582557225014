export const roundDeviation = value => {
  return value ? parseFloat(value) : null;
};

export const isDeviationExceeded = (deviation, optifluxAvg, externalAvg, skipDivide = false) => {
  if (!deviation || !optifluxAvg || !externalAvg) {
    return false;
  }
  const divider = skipDivide ? 1 : 10000;
  const isDeviating = parseFloat(deviation) < Math.abs(parseFloat(optifluxAvg) / divider - parseFloat(externalAvg));
  return isDeviating;
};
