import React, { useEffect } from 'react';
import { Slider, Typography } from 'antd';

export default props => {
  const { Text } = Typography;
  const {
    max,
    min,
    marks,
    defaultValue,
    labelSub,
    label,
    color,
    disableCard,
    handleStyle,
    trackStyle,
    maxLabel,
  } = props;

  // useEffect(() => {
  //   console.log(marks);
  // }, [marks]);

  const style = {
    backgroundColor: color,
  };

  return (
    <div style={{ margin: '30px 10px' }} className="card-view-slider-main">
      <Text className="icon-wrapper" strong disabled={disableCard}>
        {label}
        <sub>{labelSub}</sub>
      </Text>
      <div className="icon-wrapper">
        <p className="anticon" style={{ marginLeft: '-25px' }}>
          {min}
        </p>
        <Slider
          max={max}
          min={min}
          value={defaultValue}
          marks={marks}
          tooltipVisible
          tooltipPlacement="bottom"
          step={0.01}
          trackStyle={trackStyle || style}
          disabled={disableCard}
          handleStyle={handleStyle}
          getTooltipPopupContainer={node => node.parentNode}
        />
        <p className="anticon">{maxLabel}</p>
      </div>
    </div>
  );
};
