import * as jwt from 'jsonwebtoken';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_URL, MAX_AGE, TOKEN } from '../utility/constants';

const cookies = new Cookies();

export default class LoginService {
  setToken(token) {
    cookies.set(TOKEN, token, {
      maxAge: MAX_AGE,
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    localStorage.setItem(TOKEN, token);
  }

  getToken() {
    let token = cookies.get(TOKEN, {
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    if (!token) {
      token = localStorage.getItem(TOKEN);
    }
    return token;
  }

  getDecodedToken() {
    const token = this.getToken();
    const decodedToken = jwt.decode(token, { complete: true });
    return decodedToken;
  }

  getName() {
    const decodedToken = this.getDecodedToken();
    return decodedToken.payload.name;
  }

  isLoggedin() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  deleteCookie() {
    cookies.remove(TOKEN, {
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    localStorage.clear();
  }

  Logout() {
    this.deleteCookie();
  }

  loginUser(email, password) {
    const credentials = {
      email,
      password,
    };
    return axios.post(`${API_URL}/users/login`, credentials);
  }

  forgetPassword(email) {
    const credentials = {
      email,
    };
    return axios.post(`${API_URL}/users/forgot_password`, credentials);
  }
}
