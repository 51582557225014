export const selectOptionValues = (data, key) => {
  return async dispatch => {
    try {
      dispatch({
        type: 'SELECT_OPTIONS',
        payload: { data, key },
      });
    } catch (err) {
      console.error(err);
    }
  };
};
