import { useQuery } from '@tanstack/react-query';
import WatchdogMachineService from '../service/watchdog-machine';

const watchdogService = new WatchdogMachineService();

/**
 *
 * @param {any[]} contents
 * @param {string} type
 * @returns
 */
export default function useMachineCycle(roomId, options = {}, days, range = false) {
  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ['machine-cycle', { room: roomId, days, range }],
    queryFn: () =>
      watchdogService.getTabData({
        room_id: roomId,
        days,
        startRange: range ? range.start : '',
        endRange: range ? range.end : '',
      }),
    enabled: !!roomId,
    select: res => res.data,
    ...options,
  });

  return {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
}
