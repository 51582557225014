import styled from 'styled-components';

const ModalContentContainer = styled.div`
  width: 70vw;
  /* height: fit-content; */
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Footer = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px 5px 10px auto;
`;

const RoomContentsContainer = styled.div`
  width: 100%;
  .table-responsive {
    width: inherit !important;
  }
  max-height: 300px;
  overflow-y: scroll;
`;

export { ModalContentContainer, Footer, RoomContentsContainer };
