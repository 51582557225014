import { getItem, setItem } from '../../utility/localStorageControl';

const filterKey = 'filter';
const previouslySelectedFilter = getItem(filterKey);
export const defaultFilter = {
  cultivar: [],
  grower: [],
  orchard: [],
  site: [],
  treatment: [],
  harvest: '',
  harvest_date: '',
};
const initialState = previouslySelectedFilter || defaultFilter;

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTER':
      setItem(filterKey, action?.payload);
      return action?.payload;
    default:
      return state;
  }
};
export default filterReducer;
