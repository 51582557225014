import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { sections } from '../../utility/constants';

const { optiControl } = sections;

function header(props) {
  const { companyName } = props;
  const day = moment(new Date(), 'YYYY/MM/DD').date();
  const month = 1 + moment(new Date(), 'YYYY/MM/DD').month();
  const year = moment(new Date(), 'YYYY/MM/DD').year();
  const time = moment(new Date()).format('HH:mm');
  return (
    <Row justify="space-between">
      <Col span={12}>
        <div className="d-flex">
          <div>
            <img
              className="ant-dropdown-link"
              style={{
                maxWidth: '200px',
                width: '100%',
              }}
              src={require(`../../static/img/optiflux-logo.png`)}
              alt=""
            />
          </div>
          <div>
            <sup
              style={{
                top: '0em',
                margin: '0px 0px 0px -31px ',
                fontSize: '20px ',
                color: 'grey',
              }}
            >
              {optiControl.label}
            </sup>
          </div>
        </div>
      </Col>
      <Col span={12} className="d-flex justify-content-end">
        <Col
          span={24}
          style={{
            textAlign: 'end',
          }}
        >
          <p
            style={{
              marginBottom: '0px',
              color: 'grey',
            }}
          >
            Export: {`${year}/${month}/${day}  ${time}`}
          </p>
          <h2
            style={{
              color: 'grey',
            }}
          >
            {companyName}
          </h2>
        </Col>
      </Col>
    </Row>
  );
}

export default header;
