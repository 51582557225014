import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class UserService {
  async getUsers() {
    const res = await axios.get(`${API_URL}/users`, {
      headers: { Authorization: `Bearer ${loginservice.getToken()}` },
    });
    return res.data.data;
  }

  async impersonate(user) {
    const res = await axios.post(
      `${API_URL}/users/impersonate`,
      {
        user,
      },
      {
        headers: { Authorization: `Bearer ${loginservice.getToken()}` },
      },
    );
    return res.data?.token;
  }

  async stopImpersonating() {
    const res = await axios.post(
      `${API_URL}/users/stop-impersonation`,
      {},
      { headers: { Authorization: `Bearer ${loginservice.getToken()}` } },
    );
    return res.data?.token;
  }

  getUserCompany(id) {
    const token = loginservice.getToken();
    const data = {
      user_id: id,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/get_user_company`,
    };

    return axios.post(option.url, data, config);
  }

  updateUserDetails(data) {
    const token = loginservice.getToken();

    const payload = {
      user_id: data.getFieldValue('id'),
      firstname: data.getFieldValue('firstname'),
      lastname: data.getFieldValue('lastname'),
      email: data.getFieldValue('email'),
      role_id: data.getFieldValue('role_id'),
      password: data.getFieldValue('password'),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/update_user`,
    };

    return axios.put(option.url, payload, config);
  }

  getUserDetail() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log('config', config);

    const option = {
      url: `${API_URL}/users/get_user_detail`,
    };

    return axios.get(option.url, config);
  }

  userOldPassword(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log('config', config);

    const option = {
      url: `${API_URL}/users/validate_old_password`,
    };

    return axios.post(option.url, data, config);
  }

  updateStatus(value) {
    const token = loginservice.getToken();
    const data = {
      token: value,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/statusupdate`,
    };

    return axios.post(option.url, data, config);
  }
}
