import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class UloService {
  static getProfiles(companyId) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo/${companyId}`,
    };

    return axios.get(option.url, config);
  }

  static applyProfile(body) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo`,
    };

    return axios.put(option.url, body, config);
  }

  static deleteProfile(id) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo/${id}`,
    };

    return axios.delete(option.url, config);
  }

  static deleteAppliedProfile(roomId) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo/appliedProfile/${roomId}`,
    };

    return axios.delete(option.url, config);
  }

  static resumeAppliedProfile(roomId) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo/appliedProfile`,
    };

    return axios.put(option.url, { roomId }, config);
  }

  static getSetValuesByProfile(profileId) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/ulo/setValues/${profileId}`,
    };

    return axios.get(option.url, config);
  }
}
