export const hueToColors = {
  117: [0.30196078431372547, 0.5450980392156862, 0.22745098039215686],
  116: [0.3150326797385621, 0.5668845315904139, 0.2374727668845316],
  115: [0.3281045751633987, 0.5886710239651416, 0.24749455337690632],
  114: [0.3411764705882353, 0.6104575163398692, 0.2575163398692811],
  113: [0.35424836601307186, 0.632244008714597, 0.2675381263616558],
  112: [0.3673202614379085, 0.6540305010893246, 0.2775599128540305],
  111: [0.3803921568627451, 0.6758169934640522, 0.2875816993464052],
  110: [0.39346405228758174, 0.69760348583878, 0.29760348583877994],
  109: [0.4065359477124183, 0.7193899782135077, 0.30762527233115466],
  108: [0.4196078431372549, 0.7411764705882353, 0.3176470588235294],
  107: [0.44313725490196076, 0.7835294117647059, 0.33647058823529413],
  106: [0.4666666666666667, 0.8258823529411764, 0.3552941176470588],
  105: [0.49019607843137253, 0.8682352941176471, 0.37411764705882355],
  104: [0.5137254901960784, 0.9105882352941176, 0.39294117647058824],
  103: [0.5372549019607843, 0.9529411764705882, 0.4117647058823529],
  102: [0.5490196078431373, 0.8941176470588236, 0.2725490196078431],
  101: [0.5607843137254902, 0.8352941176470589, 0.13333333333333333],
  100: [0.7803921568627451, 1.0, 0.3764705882352941],
  99: [0.8300653594771241, 1.0, 0.34248366013071896],
  98: [0.8797385620915033, 1.0, 0.3084967320261438],
  97: [0.9294117647058824, 1.0, 0.27450980392156865],
  96: [0.9516339869281045, 1.0, 0.2496732026143791],
  95: [0.9738562091503268, 1.0, 0.22483660130718955],
  94: [0.996078431372549, 1.0, 0.2],
  93: [0.9901960784313726, 0.9686274509803922, 0.2196078431372549],
  92: [0.984313725490196, 0.9372549019607843, 0.23921568627450981],
  91: [0.9784313725490196, 0.9058823529411765, 0.25882352941176473],
  90: [0.9725490196078431, 0.8745098039215686, 0.2784313725490196],
  89: [0.9666666666666667, 0.8431372549019608, 0.2980392156862745],
};

export const hueToHex = [
  {
    value: 89,
    color: '#f7d74c',
  },
  {
    value: 90,
    color: '#f8df47',
  },
  {
    value: 91,
    color: '#fae742',
  },
  {
    value: 92,
    color: '#fbef3d',
  },
  {
    value: 93,
    color: '#fdf738',
  },
  {
    value: 94,
    color: '#feff33',
  },
  {
    value: 95,
    color: '#f8ff39',
  },
  {
    value: 96,
    color: '#f3ff40',
  },
  {
    value: 97,
    color: '#edff46',
  },
  {
    value: 98,
    color: '#e0ff4f',
  },
  {
    value: 99,
    color: '#d4ff57',
  },
  {
    value: 100,
    color: '#c7ff60',
  },
  {
    value: 101,
    color: '#8fd522',
  },
  {
    value: 102,
    color: '#8ce446',
  },
  {
    value: 103,
    color: '#89f369',
  },
  {
    value: 104,
    color: '#83e864',
  },
  {
    value: 105,
    color: '#7ddd5f',
  },
  {
    value: 106,
    color: '#77d35b',
  },
  {
    value: 107,
    color: '#71c856',
  },
  {
    value: 108,
    color: '#6bbd51',
  },
  {
    value: 109,
    color: '#68b74e',
  },
  {
    value: 110,
    color: '#64b24c',
  },
  {
    value: 111,
    color: '#61ac49',
  },
  {
    value: 112,
    color: '#5ea747',
  },
  {
    value: 113,
    color: '#5aa144',
  },
  {
    value: 114,
    color: '#579c42',
  },
  {
    value: 115,
    color: '#54963f',
  },
  {
    value: 116,
    color: '#50913d',
  },
  {
    value: 117,
    color: '#4d8b3a',
  },
];

function componentToHex(rgbColorComponent) {
  const hex = rgbColorComponent.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export const hueToHexConverter = () => {
  const toHex = [];
  Object.keys(hueToColors).forEach(key => {
    const [r, g, b] = hueToColors[key];
    toHex.push({ value: key, color: rgbToHex(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)) });
  });
  return toHex;
};

export const colorCardValues = [
  {
    score: 1,
    label: 1,
    color: '#799b64',
    startHue: 117.59,
  },
  {
    score: 2,
    label: 2,
    color: '#8f9b32',
    startHue: 108.57,
  },
  {
    score: 3,
    label: 3,
    color: '#969b26',
    startHue: 105,
  },
  {
    score: 4,
    label: '4',
    color: '#a4a420',
    startHue: 103,
  },
  {
    score: 5,
    label: '5',
    color: '#b7b65a',
    startHue: 101,
  },
  {
    score: 6,
    label: 6,
    color: '#d1c85f',
    startHue: 97.07,
  },
  {
    score: 7,
    label: 7,
    color: '#d7c951',
    startHue: 94.99,
  },
  {
    score: 8,
    label: 8,
    color: '#e9c74b',
    startHue: 88,
  },
];

export const getScoreFromHue = hue => {
  return colorCardValues.reduce(
    (acc, values) => {
      const { score, startHue, label } = values;
      const parsedHue = parseFloat(hue);
      const hueDiff = Math.abs(startHue - parsedHue);
      if (hueDiff <= acc.hueDiff) {
        acc.score = score;
        acc.hueDiff = hueDiff;
        acc.label = label;
      }
      return acc;
    },
    { score: 1, hueDiff: 120, label: 1 },
  );
};

export const colorScoreToHue = scoreToConvert => {
  let calculatedHue = 0;
  colorCardValues.forEach((values, i) => {
    if (!colorCardValues[i + 1]) {
      return;
    }
    const { score, startHue } = values;
    const { score: nextScore, startHue: nextStartHue } = colorCardValues[i + 1];
    const parsedColorScore = parseFloat(scoreToConvert);
    if (parsedColorScore >= score && parsedColorScore <= nextScore) {
      calculatedHue = startHue - (parsedColorScore - score) * (startHue - nextStartHue);
    }
  });
  return calculatedHue;
};

export const colorScoreLimit = 10;

export const hueToDecimalColorScore = hue => {
  let calculatedScore = 0;
  colorCardValues.forEach((values, i) => {
    if (!colorCardValues[i + 1]) {
      return;
    }
    const { score, startHue } = values;
    const { startHue: nextStartHue } = colorCardValues[i + 1];
    const parsedHue = parseFloat(hue);
    if (parsedHue <= startHue && parsedHue >= nextStartHue) {
      calculatedScore = score + (startHue - parsedHue) / (startHue - nextStartHue);
    }
  });
  return calculatedScore;
};
