import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

const SelectInput = ({
  options,
  handleChange,
  placeholder,
  identifier,
  selectedValues,
  mode,
  disabled,
  defaultValue,
  style,
}) => {
  return (
    <Select
      showArrow
      allowClear
      style={style || { minWidth: '220px', maxWidth: '400px', textAlign: 'left' }}
      optionFilterProp="label"
      placeholder={placeholder || ''}
      onChange={handleChange}
      mode={mode}
      key={identifier}
      value={selectedValues}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {options.map(option => (
        <Option title={option.label} {...option}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
