const initialState = {
  roomId: '',
};

const roomReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ROOM_ID':
      return {
        ...state,
        roomId: action.payload,
      };

    default:
      return state;
  }
};
export default roomReducer;
