import { useQuery, useQueryClient } from '@tanstack/react-query';
import DashboardService from '../service/dashboard';

const defaultFilter = { company: '', site: '', license: '' };
const service = new DashboardService();
export default function useDashboard(filters = defaultFilter, queryOptions = {}, selectFilter = {}) {
  const queryKey = ['dashboard', filters];
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey,
    queryFn: () => service.getTableData(filters),
    select: response => {
      const { searchTags } = selectFilter;
      return searchTags?.length
        ? response.data.data?.filter(room => room?.tags.some(tag => searchTags.includes(tag.id)))
        : response.data.data;
    },
    enabled: !!filters.company && filters.company !== '',
    ...queryOptions,
  });

  const invalidate = () => {
    return queryClient.invalidateQueries(queryKey);
  };

  return { rooms: data || [], isLoading, error, refetch, isFetching, invalidate };
}
