import axios from 'axios';
import { API_URL } from '../utility/constants';
import LoginService from './login';

const loginservice = new LoginService();

export default class DashboardService {
  getTableData(filters) {
    const token = loginservice.getToken();
    const params = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params,
    };

    const option = {
      url: `${API_URL}/dashboard/rooms/list`,
    };

    return axios.get(option.url, config);
  }

  uploadRoomData(data) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/dashboard/import_file_data`,
    };

    return axios.post(option.url, data, config);
  }

  saveContainer(container) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/containers`,
    };

    return axios.put(option.url, container, config);
  }

  deleteContainer(id) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/containers/${id}`,
    };

    return axios.delete(option.url, config);
  }

  getContainerContents(id) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/room_contents/byContainer/${id}`,
    };

    return axios.get(option.url, config);
  }
}
