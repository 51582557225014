import { DatePicker, Form } from 'antd';
import React from 'react';

const DateInput = ({ field, required, label }) => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div key={field}>
      <Form.Item hasFeedback label={label} name={field} rules={[{ required, message: `${label} is required` }]}>
        <DatePicker style={{ width: '100%', height: '3rem', paddingLeft: '1rem' }} size="large" onChange={onChange} />
      </Form.Item>
    </div>
  );
};

export default DateInput;
