export const roomHandler = data => {
  return async dispatch => {
    try {
      dispatch({
        type: 'ROOM_ID',
        payload: data,
      });
    } catch (err) {
      console.log('catch');
    }
  };
};
