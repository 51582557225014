import { Form, Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

const TextAreaInput = ({ label, field, required }) => {
  return (
    <div key={field}>
      <Form.Item hasFeedback label={label} name={field} rules={[{ required, message: `${label} is required` }]}>
        <TextArea rows={3} />
      </Form.Item>
    </div>
  );
};

export default TextAreaInput;
