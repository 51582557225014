import React from 'react';
import { DownOutlined, SettingOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';

export default function OptiControlTableActions({
  onChannelSettings,
  onPauseUloProfile,
  onResumeUloProfile,
  onEditRoomSettings,
  onForceInjection,
  onForceMeasurement,
  channelState,
  onViewUlo,
}) {
  const menu = (
    <Menu>
      {onChannelSettings && (
        <Menu.Item key="editRoomSettings" onClick={onChannelSettings}>
          <SettingOutlined size={12} /> Settings
        </Menu.Item>
      )}
      {onPauseUloProfile && (
        <Menu.Item key="pauseUloProfile" onClick={onPauseUloProfile}>
          <FeatherIcon icon="pause" size={12} /> Pause ULO Profile
        </Menu.Item>
      )}
      {onResumeUloProfile && (
        <Menu.Item key="resumeUloProfile" onClick={onResumeUloProfile}>
          <FeatherIcon icon="play" size={12} /> Resume ULO Profile
        </Menu.Item>
      )}
      {onViewUlo && (
        <Menu.Item key="viewUloProfile" onClick={onViewUlo}>
          <FeatherIcon icon="eye" size={12} /> View ULO Profile
        </Menu.Item>
      )}
      {onChannelSettings && onForceInjection && (
        <Menu.Item key="forceInjection" onClick={onForceInjection}>
          <FeatherIcon icon={channelState === 'idle' ? 'play' : 'stop'} size={12} />{' '}
          {channelState === 'idle' ? 'Start' : 'Stop'} Injection
        </Menu.Item>
      )}
      {onForceMeasurement && (
        <Menu.Item key="forceMeasurement" onClick={onForceMeasurement}>
          <FeatherIcon icon={channelState === 'idle' ? 'play' : 'stop'} size={12} />{' '}
          {channelState === 'idle' ? 'Start' : 'Stop'} Measurement
        </Menu.Item>
      )}
    </Menu>
  );

  if (!onChannelSettings && !onPauseUloProfile && !onResumeUloProfile && !onForceInjection && !onForceMeasurement) {
    return (
      <Button className="btn-info" type="info" shape="circle" onClick={onEditRoomSettings}>
        <EditOutlined size={16} />
      </Button>
    );
  }

  return (
    <Dropdown.Button
      overlay={menu}
      className="btn-info"
      type="info"
      shape="circle"
      icon={<DownOutlined />}
      onClick={onEditRoomSettings}
    >
      <EditOutlined size={16} />
    </Dropdown.Button>
  );
}
