const initialState = {
  enabled: false,
};

export const SWITCH_DEMO_MODE = 'switch_demo_mode';
export const ACTIVATE_DEMO_MODE = 'activate_demo_mode';
export const DEACTIVE_DEMO_MODE = 'deactivate_demo_mode';

export const demoModeReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SWITCH_DEMO_MODE:
      return {
        ...state,
        enabled: !state.enabled,
      };
    case ACTIVATE_DEMO_MODE:
      return { ...state, enabled: true };
    case DEACTIVE_DEMO_MODE:
      return { ...state, enabled: false };
    default:
      return state;
  }
};
