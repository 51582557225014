import { Col, Dropdown, Layout, Menu, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AuthInfo from '../components/utilities/auth-info/info';
import CompaniesService from '../service/companies';
import LoginService from '../service/login';
import TopMenu from './TopMenu';
import { Div } from './style';
import UserSelect from '../components/UserSelect';
import { sections, storageOptimumRoute } from '../utility/constants';

const { home } = sections;

const footerStyle = {
  padding: '20px 30px 18px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontSize: '14px',
  background: 'rgba(255, 255, 255, .90)',
  width: '100%',
  boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
};

const companiesService = new CompaniesService();
const loginService = new LoginService();

const getMenuItems = () => {
  const parents = Object.values(sections).filter(p => !p.parent);
  const children = Object.values(sections).filter(c => !!c.parent);
  return parents.map(p => {
    const parentChildren = children.map(o => ({ key: o.route, ...o })).filter(o => o.parent === p.route);
    const menuSection = { key: p.route, ...p };
    if (parentChildren?.length) {
      menuSection.children = parentChildren;
    }
    return menuSection;
  });
};

export default function AppLayout({ children }) {
  const dispatch = useDispatch();
  const dashboardState = useSelector(state => state.Dashboard);
  const demoMode = useSelector(state => state.demoMode);
  const [selectedPage, setSelectedPage] = useState(home);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(dashboardState?.companyId || '');
  const [user, setUser] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (user?.role_id === 1 && !company && companies?.length) {
      const [c] = companies;
      setCompany(c.id);
      dispatch({
        type: 'COMPANY_ID',
        payload: { id: c.id, name: c.name, slug: c.slug },
      });
    }
  }, [companies, company, dispatch, user]);

  useEffect(() => {
    const pathName = location?.pathname;
    const activeLink =
      sections[
        Object.keys(sections).find(key => {
          return pathName.includes(sections[key].route);
        })
      ];
    if (activeLink) {
      setSelectedPage(activeLink);
    }

    const token = loginService.getDecodedToken();
    setUser(token?.payload);
    companiesService.getCompanies().then(response => {
      setCompanies(response.data.data);
    });
  }, [location.pathname]);

  const handleMenuClick = useCallback(
    event => {
      const { item } = event;
      const parentOrChild =
        sections[Object.keys(sections).find(key => sections[key].route === item?.props?.route)] ||
        Object.values(sections)
          .map(v => v.children)
          .flat()
          .filter(v => !!v)
          .find(c => c.route === item?.props?.route);
      setSelectedPage({ ...parentOrChild, key: item.key });
      const newPath = `/admin/${item?.props?.route}/dashboard`;
      history.push(newPath);
    },
    [history],
  );

  const handleCompanyChange = useCallback(
    selected => {
      setCompany(selected);
      const selectedCompanies = companies?.find(c => c.id === selected);
      dispatch({
        type: 'COMPANY_ID',
        payload: { id: selected, name: selectedCompanies?.name, slug: selectedCompanies?.slug },
      });
    },
    [companies, dispatch],
  );

  const scrambleCompanyName = c => {
    return `Company ${(c.id + 9).toString(36).toUpperCase()}`;
  };
  const menu = (
    <Menu
      mode="inline"
      defaultOpenKeys={[storageOptimumRoute]}
      selectedKeys={[selectedPage?.route]}
      onClick={handleMenuClick}
      items={getMenuItems()}
    />
  );

  return (
    <Div darkMode={false}>
      <Layout className="layout">
        <Layout.Header
          style={{
            position: 'fixed',
            width: '100vw',
            top: 0,
          }}
          className="pdf-generate-hide header-navbar"
        >
          <Row className="dash-board-drop">
            <Col lg={4} md={9} sm={9} xs={12} className="align-center-v navbar-brand">
              <Link className="striking-logo top-menu super-text" to="#">
                <div>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <img className="ant-dropdown-link" src={require(`../static/img/custom/optiflux-logo.png`)} alt="" />
                  </Dropdown>
                </div>
                <div>
                  <sup>{selectedPage.label}</sup>
                </div>
              </Link>
            </Col>

            <Col className="company-select" xl={4} lg={3} md={11} sm={11} xs={10}>
              {user?.role_id === 1 && (
                <Select value={company} onChange={handleCompanyChange} style={{ width: 150 }} bordered={false}>
                  {companies.map((c, index) => {
                    return (
                      <Select.Option key={c.id} value={c.id}>
                        {demoMode.enabled ? scrambleCompanyName(c, index) : `${c.name} (${c.slug || 'NA'})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
              {(user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 4) && (
                <span>{dashboardState?.companyName}</span>
              )}
            </Col>

            <Col xl={9} lg={9} md={0} sm={0} xs={0} className="pdf-generate-hide" style={{ width: 'fit-content' }}>
              <TopMenu route={selectedPage.route} />
            </Col>

            <Col xl={4} lg={4} md={0} sm={0} xs={0}>
              <UserSelect />
            </Col>

            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
              <AuthInfo />
            </Col>
          </Row>
        </Layout.Header>

        <Layout>
          <Layout className="atbd-main-layout">
            <Layout.Content>
              {children}
              <Layout.Footer className="admin-footer" style={footerStyle}>
                <Row>
                  <Col md={12} xs={24}>
                    <span className="admin-footer__copyright">{`${new Date().getFullYear()} © Optiflux`}</span>
                  </Col>
                </Row>
              </Layout.Footer>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    </Div>
  );
}

AppLayout.propTypes = {};
