export const formatRqValue = (value, type) => {
  if (type === 'range') {
    if (value) {
      const [min, max] = value.split(',');
      return { min, max };
    }
    return {
      min: '',
      max: '',
    };
  }
  return value;
};
