import Styled from 'styled-components';

const AlertDashboardStyle = Styled.div`
margin-bottom:50px;
.ant-card{
    background: #f9e0df;
    border:1px solid #f4c8c7;
}
.table-responsive .ant-table-content {
    background: #f9e0df;
    .ant-table-cell {
        background: #f9e0df;
        border:none;
        border-bottom:1px solid #f0d7d7;
    }
}       
`;

const TimeField = Styled.span`
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const TableContainer = Styled.div`
  td.ant-table-column-sort {
    background: unset;
  }
`;

export { AlertDashboardStyle, TimeField, TableContainer };
