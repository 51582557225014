import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class TagService {
  getTagsByCompany(companyId) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/tags/${companyId}`,
    };
    return axios.get(option.url, config);
  }

  saveTag(tag) {
    const { companyId, name } = tag;
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/tags`,
    };
    return axios.post(option.url, { companyId, name }, config);
  }
}
