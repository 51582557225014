export const selectFilterHandler = data => {
  return async dispatch => {
    try {
      dispatch({
        type: 'FILTER',
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };
};
