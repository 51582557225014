import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import { ThemeProvider } from 'styled-components';
import config from './config/config';
import store from './redux/store';
import './static/css/style.css';
import RouterHelper from './utility/routerHelper';

const { theme } = config;

const queryClient = new QueryClient();

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
          <Router basename={process.env.PUBLIC_URL}>
            <RouterHelper />
          </Router>
        </ThemeProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
