import { QrcodeOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Select, Space, Spin, Typography } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFirstRender from '../../hooks/useFirstRender';
import { selectFilterHandler } from '../../redux/filter/actions';
import { defaultFilter } from '../../redux/filter/reducers';
import CultivarsService from '../../service/cultivars';
import GrowersService from '../../service/growers';
import LoginService from '../../service/login';
import OrchardsService from '../../service/orchards';
import SitesService from '../../service/sites';
import TreatmentsService from '../../service/treatments';
import { getItem } from '../../utility/localStorageControl';
import { Button } from '../buttons/buttons';
import { HeaderWrapper, QRCodeContainer } from './style';
import { naturalSort } from '../../utility/helpers';

const loginService = new LoginService();

const cultivarsService = new CultivarsService();
const growersService = new GrowersService();
const orchardsService = new OrchardsService();
const sitesService = new SitesService();
const treatmentsService = new TreatmentsService();

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;
export const styleDrop = {
  border: '1px solid #d9d9d9',
  fontWeight: 600,
  textAlign: 'left',
  background: 'white',
  color: 'black',
  // display: dropdownOpen ? 'block' : 'none',
};
const DashboardHeader = props => {
  const userData = getItem('user');
  const user = loginService.getDecodedToken();
  const globalStates = useSelector(state => state?.Dashboard);
  const filterState = useSelector(state => state.filter);
  const dateFormat = 'DD/MM/YYYY';
  const {
    handleLiveReader,
    toggleDashboardView,
    tableView,
    handleQrCode,
    handleCreatePdf,
    showLoader,
    isMachineCycle,
  } = props;
  const [state, setState] = useState({
    harvest: false,
  });
  const [filters, setFilters] = useState(filterState);

  const [growers, setGrowers] = useState([]);
  const [cultivars, setCultivars] = useState([]);
  const [orchards, setOrchards] = useState([]);
  const [sites, setSites] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [growerIds, setGrowerIds] = useState([]);
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();
  const handleChange = (value, name) => {
    setFilters(st => ({
      ...st,
      [name]: value,
    }));
  };
  const handleTypeChange = (value, name) => {
    setState(st => ({
      ...st,
      [name]: value,
    }));

    if (value === '') {
      setFilters(st => ({
        ...st,
        [name]: value,
        harvest_date: '',
      }));
    }
  };
  useEffect(() => {
    dispatch(selectFilterHandler(filters));
  }, [filters]);
  useEffect(() => {
    cultivarsService
      .getCultivars()
      .then(response => {
        setCultivars(response.data.data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    treatmentsService
      .getTreatments()
      .then(response => {
        setTreatments(response.data.data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);
  useEffect(() => {
    sitesService
      .getSitesbyId(globalStates?.companyId ? { company_id: globalStates?.companyId } : {})
      .then(response => {
        const companySites = response.data.data;
        setSites(companySites);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [globalStates?.companyId]);
  useEffect(() => {
    if (!isFirstRender) {
      setFilters(defaultFilter);
    }
  }, [globalStates.companyId]);
  useEffect(() => {
    growersService
      .getGrowers(globalStates?.companyId ? { company_id: globalStates?.companyId } : {})
      .then(response => {
        setGrowers(response.data.data);
        const growerId = response.data.data.map(x => x.id);
        setGrowerIds(growerId);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [globalStates?.companyId]);
  useEffect(() => {
    orchardsService
      .getOrchards(growerIds.length > 0 ? { growers_id: growerIds } : {})
      .then(response => {
        setOrchards(response.data.data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [growerIds]);
  const onChange = value => {
    setFilters(st => ({
      ...st,
      // eslint-disable-next-line no-underscore-dangle
      harvest_date: value === null ? '' : new Date(value._d).toISOString().split('T')[0],
      harvest: value === null ? '' : state.harvest,
    }));
  };
  const onChangeRange = value => {
    setFilters(st => ({
      ...st,
      // eslint-disable-next-line no-underscore-dangle
      harvest_date:
        value === null
          ? ''
          : `${new Date(value[0]._d).toISOString().split('T')[0]},${new Date(value[1]._d).toISOString().split('T')[0]}`,
      harvest: value === null ? '' : state.harvest,
    }));
  };

  return (
    <>
      <HeaderWrapper>
        {!isMachineCycle && (
          <Row justify="space-between" className="main-row-header">
            <Col lg={10} className="align-center-v">
              <Title level={5}>Overview</Title>
              <Button onClick={toggleDashboardView} type="default" className="over-view-button">
                <FeatherIcon icon={tableView ? 'grid' : 'list'} size={20} />
              </Button>
            </Col>

            <Col lg={10} className="header-top-right">
              <Space>
                {showLoader === false && (
                  <Button
                    type="default"
                    onClick={() => {
                      handleCreatePdf();
                    }}
                  >
                    <FeatherIcon icon="download" size={16} />
                  </Button>
                )}
                {showLoader === true && (
                  <Spin
                    style={{
                      marginRight: '20px',
                      marginTop: '7px',
                    }}
                  />
                )}
                <QRCodeContainer>
                  <Button type="info" onClick={() => handleQrCode()}>
                    <QrcodeOutlined />
                  </Button>{' '}
                </QRCodeContainer>
                <button
                  type="button"
                  className="primary_button"
                  onClick={() => {
                    handleLiveReader();
                  }}
                >
                  Manual Measurements
                </button>
              </Space>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={24}>
            <Row justify="space-between">
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  onChange={e => {
                    handleChange(e, 'cultivar');
                  }}
                  value={filters.cultivar ? filters.cultivar : []}
                  bordered={false}
                  mode="multiple"
                  // allowClear
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Cultivars"
                >
                  {cultivars
                    .sort((a, b) => naturalSort(a.title, b.title))
                    .map(cultivar => {
                      return (
                        <Option key={cultivar.id} value={cultivar.id}>
                          {cultivar.title}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              {user?.payload?.role_id !== 4 && (
                <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                  <Select
                    onChange={e => {
                      handleChange(e, 'grower');
                    }}
                    value={filters.grower ? filters.grower : []}
                    bordered={false}
                    mode="multiple"
                    showSearch={false}
                    showArrow
                    dropdownStyle={styleDrop}
                    defaultValue={[]}
                    placeholder="Growers"
                  >
                    {growers
                      .sort((a, b) => naturalSort(a.name, b.name))
                      .map(grower => {
                        return (
                          <Option key={`growerOption_${grower?.id}`} value={grower.id}>
                            {grower.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
              )}
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  onChange={e => {
                    handleChange(e, 'orchard');
                  }}
                  value={filters.orchard ? filters.orchard : []}
                  bordered={false}
                  mode="multiple"
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Orchards"
                >
                  {orchards
                    .sort((a, b) => naturalSort(a.name, b.name))
                    .map(orchard => {
                      return (
                        <Option key={`orchardOption_${orchard.id}`} value={orchard.id}>
                          {orchard.name}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                {user?.payload?.role_id === 3 || user?.payload?.role_id === 4 ? (
                  <Select
                    mode="multiple"
                    onChange={e => {
                      handleChange(e, 'site');
                    }}
                    value={filters.site ? filters.site : []}
                    bordered={false}
                    showSearch={false}
                    showArrow
                    dropdownStyle={styleDrop}
                    defaultValue={[]}
                    placeholder="Sites"
                  >
                    {userData?.company_sites
                      ?.sort((a, b) => naturalSort(a.name, b.name))
                      .map(site => {
                        return (
                          <Option key={`siteOption_${site.id}`} value={site.id}>
                            {site.name}
                          </Option>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    mode="multiple"
                    onChange={e => {
                      handleChange(e, 'site');
                    }}
                    value={filters.site ? filters.site : []}
                    bordered={false}
                    showSearch={false}
                    showArrow
                    dropdownStyle={styleDrop}
                    defaultValue={[]}
                    placeholder="Sites"
                  >
                    {sites
                      ?.sort((a, b) => naturalSort(a.name, b.name))
                      .map(site => {
                        return (
                          <Option key={`siteMultiple_${site?.id}`} value={site.id}>
                            {site.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  mode="multiple"
                  onChange={e => {
                    handleChange(e, 'treatment');
                  }}
                  value={filters.treatment ? filters.treatment : []}
                  bordered={false}
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Treatments"
                >
                  {treatments
                    .sort((a, b) => naturalSort(a.title, b.title))
                    .map(treatment => {
                      return (
                        <Option key={`treatment_${treatment.id}`} value={treatment.id}>
                          {treatment.title}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  mode="multiple"
                  onChange={e => {
                    handleChange(e, 'mode');
                  }}
                  value={filters.mode ? filters.mode : []}
                  bordered={false}
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Modes"
                >
                  <Option value="ulo">ULO</Option>
                  <Option value="dca">DCA</Option>
                </Select>
              </Col>{' '}
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  defaultValue=""
                  // style={{ width: 120 }}
                  bordered={false}
                  onChange={e => {
                    handleTypeChange(e, 'harvest');
                  }}
                >
                  <Option value="">All harvest date</Option>
                  <Option value="specific">Specific date</Option>
                  <Option value="before">Before</Option>
                  <Option value="after">After</Option>
                  <Option value="range">Range</Option>
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        {(state.harvest === 'before' ||
          state.harvest === 'after' ||
          state.harvest === 'specific' ||
          state.harvest === 'range') && (
          <Row justify="end">
            <Col lg={3} xs={24} sm={7} md={7} className="d-flex ">
              {(state.harvest === 'before' || state.harvest === 'after' || state.harvest === 'specific') && (
                <DatePicker format={dateFormat} onChange={onChange} />
              )}
              {state.harvest === 'range' && <RangePicker format={dateFormat} onChange={onChangeRange} />}
            </Col>
          </Row>
        )}
      </HeaderWrapper>
    </>
  );
};
export default DashboardHeader;
