export const defaultState = {
  growers: [],
  orchards: [],
  treatments: [],
  cultivars: [],
  sites: [],
  channels: [],
  tags: [],
};

const selectOptionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_OPTIONS':
      return { ...state, [action?.payload?.key]: action?.payload?.data };
    default:
      return state;
  }
};
export default selectOptionsReducer;
