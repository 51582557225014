import { Form, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import { RoomsModalStyle } from './style';
import { Button } from '../buttons/buttons';
import { Modal } from '../modals/antd-modals';

const RoomsModal = ({
  visible,
  modalType,
  container,
  form,
  handleRoomChange,
  handleRoomModalcancel,
  rooms,
  selected,
}) => {
  const [selectedRooms, setSelectedRooms] = useState(selected);
  const styleDrop = {
    border: '1px solid #d9d9d9',
    fontWeight: 600,
    textAlign: 'center',
  };

  const options = useMemo(() => {
    return rooms?.map(r => (
      <Select.Option key={r.id} value={r.id}>
        {r.name}
      </Select.Option>
    ));
  }, [rooms]);

  return (
    <Modal
      type={modalType}
      title="Select room(s)"
      visible={visible}
      footer={null}
      onCancel={() => handleRoomModalcancel()}
      className="rooms-modal"
      zIndex={1001}
    >
      <RoomsModalStyle>
        <Form form={form} name={container} className="rooms-modal-form">
          <Form.Item>
            {visible && (
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                defaultValue={selected}
                placeholder="Please select"
                dropdownStyle={styleDrop}
                onChange={e => {
                  setSelectedRooms([...e]);
                }}
                defaultOpen
              >
                {options}
              </Select>
            )}
          </Form.Item>
        </Form>
      </RoomsModalStyle>
      <div className="btn-done-edit-pop-up" style={{ textAlign: 'end' }}>
        <Button
          htmlType="submit"
          type="success"
          key="submit"
          onClick={() => {
            if (selected.join('') !== selectedRooms.join('')) {
              handleRoomChange(selectedRooms);
            }
            handleRoomModalcancel();
          }}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
};
export default RoomsModal;
