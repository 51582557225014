import Styled from 'styled-components';

const HeaderWrapper = Styled.div`
.main-row-header{
    padding-bottom:25px;
    align-items:center;
    
    .header-top-right{
        display:flex;
        column-gap: 10px;
        justify-content: flex-end;
    }
}
.ant-picker-range{
    width:100%
}
.ant-select{
    background: #12d6a2;
    border-radius: 5px;
    color:#e2f7f2;
    font-weight:600;
    width:100%;
    .anticon{
        color:#e2f7f2;
    }
    .ant-select-selector{
        border:none;
        align-items: center;
    }
    .ant-select-selection-item{
 
        background:none!important;
    }
    .ant-select-selection-placeholder {
        color: #e2f7f2;
    }
    .ant-select-multiple .ant-select-selection-item-content {
        margin-right: 8px;
    }
    .ant-select-arrow{
        top:20px;
    }
}
.ant-cascader-picker{
    background: #12d6a2;
    border-radius: 5px;
    color:#e2f7f2;
    font-weight:600;
    .anticon{
        color:#e2f7f2;
    }
    .ant-select-selector{
        border:none;
        align-items: center;
    }
}
.ant-picker{
    height:38px;
    min-width: 120px;
    width:100%
    // background: #12d6a2;
    // border-radius: 5px;
    // color:#e2f7f2;
    // font-weight:600;
     border:1px solid #12d6a2;
     border-color:#12d6a2;;
    // box-shadow:none;
    .ant-picker-suffix{
        color:#12d6a2;
    }
    input{
        color:#12d6a2;
        font-weight: 600;
    }
}
.ant-typography{
    margin-bottom: 0em!important;
}
.primary_button{
    background: #2c99ff !important;
    color: #ffffff !important;
    border: 1px solid #2c99ff;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    cursor:pointer;

}
.primary_button:hover{
    background: #0D79DF !important;

}
`;

const QRCodeContainer = Styled.div`
display: none;
@media only screen and (max-width: 768px) {
    display: block;
}
`;

export { HeaderWrapper, QRCodeContainer };
