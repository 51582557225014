import momentTimezone from 'moment-timezone';
import zipcelx from 'zipcelx';

export const exportOnlyVisibleData = Highcharts => {
  Highcharts.wrap(Highcharts.Chart.prototype, 'getDataRows', function(proceed, multiLevelHeaders) {
    let rows = proceed.call(this, multiLevelHeaders);
    const xMin = this.xAxis[0].min;
    const xMax = this.xAxis[0].max;

    rows = rows.filter(function(row) {
      return typeof row.x !== 'number' || (row.x >= xMin && row.x <= xMax);
    });

    return rows;
  });
};

export const toggleDataGrouping = (series, boolean, interval = ['day', [1]]) => {
  series.forEach(s => {
    s.update({
      dataGrouping: {
        enabled: boolean,
        units: [interval],
      },
    });
  });
};

const dayPeriod = 24 * 3600 * 1000;
const weekPeriod = 7 * dayPeriod;
const monthPeriod = 31 * dayPeriod;
const handleDataGroupingPeriod = (start, end, days = null) => {
  const period = days ? days * dayPeriod : end - start;
  if (!start || !end || period < 5 * dayPeriod) {
    return [false, null];
  }
  if (period < 2 * weekPeriod) {
    return [true, ['hour', [1]]];
  }
  if (period < monthPeriod) {
    return [true, ['hour', [2]]];
  }
  if (period < 3 * monthPeriod) {
    return [true, ['hour', [6]]];
  }
  return [true, ['day', [1]]];
};

export function handleDataGrouping(start, end, series, isFetching, dataGroupingFun, days = null) {
  const [enabled, interval] = handleDataGroupingPeriod(start, end, days);
  if (isFetching) {
    dataGroupingFun({
      enabled,
      interval,
    });
  } else {
    toggleDataGrouping(series, enabled, interval);
  }
}

export const onDayChange = (day, setGrouping) => {
  if (day === 0) {
    setGrouping({
      enabled: true,
      interval: ['day', [1]],
    });
  } else if (day === 90) {
    setGrouping({
      enabled: true,
      interval: ['hour', [6]],
    });
  } else if (day === 30) {
    setGrouping({
      enabled: true,
      interval: ['hour', [2]],
    });
  } else {
    setGrouping({
      enabled: false,
      interval: ['day', [1]],
    });
  }
};

export const determineMinMax = chartData => {
  let min = Number.MAX_SAFE_INTEGER;
  let max = 0;
  Object.values(chartData).forEach(roomData => {
    Object.values(roomData).forEach(potentialData => {
      if (
        Array.isArray(potentialData) &&
        potentialData.length &&
        Array.isArray(potentialData[0]) &&
        Array.isArray(potentialData[potentialData.length - 1])
      ) {
        const [currentMin] = potentialData[0];
        const [currentMax] = potentialData[potentialData.length - 1];
        if (currentMin && currentMin < min) {
          min = currentMin;
        }
        if (currentMax && currentMax > max) {
          max = currentMax;
        }
      }
    });
  });

  return { min, max };
};

const getNumberOfNonDecimals = value => {
  return value.split('.')[0].length || 1;
};

// format the value so that it has exactly 4 digits in total (unless it's a big number)
export const formatYValue = (value, totalDigits = 4) => {
  const parsedValue = value.toString().replace('-', '');
  if (!parsedValue.includes('.'))
    return parsedValue.length >= totalDigits ? parsedValue : value.toFixed(totalDigits - parsedValue.length);
  if (parsedValue?.length === totalDigits + 1) return parsedValue;
  const numberOfNonDecimals = getNumberOfNonDecimals(parsedValue);
  if (numberOfNonDecimals >= totalDigits) return value.toFixed();
  const totalDecimalsNeeded = totalDigits - numberOfNonDecimals;
  return value.toFixed(totalDecimalsNeeded);
};

export const xAxisMaxPadding = 0.08;

export const getTimezoneOffset = timestamp => {
  const optifluxTimezone = 'Europe/Brussels';
  let timezone = momentTimezone.tz.guess();
  if (!timezone) {
    timezone = optifluxTimezone;
  }
  const timezoneOffset = -momentTimezone.tz(timestamp, timezone).utcOffset();
  // timestamp contains hardcoded Europe/Brussels timezone,
  // that is why we have to add 1 or 2 hours to the negative offset depending on DST
  const hardcodedOffset = momentTimezone.tz(timestamp, optifluxTimezone).utcOffset();
  return timezoneOffset + hardcodedOffset;
};

export const loadZoomPeriod = (chart, zoomPeriod) => {
  if (!zoomPeriod && chart.resetZoomButton) {
    chart.resetZoomButton.hide();
  }
  if (zoomPeriod && zoomPeriod[0]) {
    const xAxis = chart.xAxis[0];
    const [start, end] = zoomPeriod;
    xAxis.setExtremes(start, end);
    chart.showResetZoom();
  }
};

export const setZoomExtremes = (e, chart, dispatch, select) => {
  if (!e.min && !e.max) {
    dispatch(select([null, null]));
    const { resetZoomButton } = chart;
    if (resetZoomButton) {
      resetZoomButton.hide();
    }
  } else {
    dispatch(select([e.min, e.max]));
  }
};
export function includeXLSXDownload(Highcharts) {
  const chartContainer = Highcharts;
  chartContainer.Chart.prototype.downloadXLSX = function() {
    const div = document.createElement('div');
    let name;
    let xlsxRows = [];
    div.style.display = 'none';
    document.body.appendChild(div);
    const rows = this.getDataRows(true);
    xlsxRows = rows.slice(1).map(row => {
      return row.map(column => {
        return {
          type: typeof column === 'number' ? 'number' : 'string',
          value: column,
        };
      });
    });
    if (this.options.exporting.filename) {
      name = this.options.exporting.filename;
    } else if (this.title && this.title.textStr) {
      name = this.title.textStr.replace(/ /g, '-').toLowerCase();
    } else {
      name = 'chart';
    }

    zipcelx({
      filename: name,
      sheet: {
        data: xlsxRows,
      },
    });
  };
}

export const isDiffSeries = (firstSeries, secondSeries) => {
  for (let i = 0; i < firstSeries.length - 1; i += 1) {
    if (JSON.stringify(firstSeries[i]) !== JSON.stringify(secondSeries[i])) {
      return true;
    }
  }
  return false;
};

const addZeroes = (num, maxZeroes) => {
  let label = num.toString();
  const zeroesToAdd = maxZeroes - label.length;
  for (let i = 0; i < zeroesToAdd; i += 1) {
    label = `0${label}`;
  }
  return label;
};

export const convertSec = ms => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const restSeconds = totalSeconds - minutes * 60;
  return `${addZeroes(minutes, 2)}:${addZeroes(restSeconds, 2)}`;
};

export const getLastMeasurement = data => {
  return data.reduce((acc, value) => {
    const allLastTimestamps = value?.data?.roomMeasurements?.map(o => o.roomData.lastTimestamp || 0);
    const currentDataLastTimestamp = Math.max(...allLastTimestamps);
    if (currentDataLastTimestamp > acc) {
      return currentDataLastTimestamp;
    }
    return acc;
  }, 0);
};
